<script setup lang="ts">
import { isToday } from "date-fns";
import UIButton from "~/components/UI/Button/Button.vue";
import UIButtonIcon from "~/components/UI/Button/ButtonIcon.vue";
import type { View } from "~/server/constants/sale";
import { DEFAULT_PERIOD } from "~/server/constants/sale";
import { searchParamsSchema } from "~/validations/sale";
import SalesPaymentMethods from "~/components/Sales/SalesPaymentMethods.vue";
import { defineAsyncComponent } from "vue";

const searchParams = useSearchParams(searchParamsSchema);

const view = ref<View>(searchParams.view ?? "sales");
const period = ref(
  searchParams.periodStart && searchParams.periodEnd
    ? {
        start: searchParams.periodStart,
        end: searchParams.periodEnd,
      }
    : DEFAULT_PERIOD(),
);
const category = ref(searchParams.category);
const cashier = ref(
  searchParams.cashierId
    ? {
        id: searchParams.cashierId,
        name: searchParams.cashierName,
      }
    : undefined,
);
const products = ref(
  searchParams.products
    ? (JSON.parse(searchParams.products) as { id: string; name: string }[])
    : undefined,
);
const paymentMethod = ref(
  searchParams.paymentMethodId
    ? {
        id: searchParams.paymentMethodId,
        name: searchParams.paymentMethodName,
      }
    : undefined,
);

const cashierId = computed(() => cashier.value?.id);
const paymentMethodId = computed(() => paymentMethod.value?.id);
const productIds = computed(() =>
  products.value && products.value.length > 0
    ? JSON.stringify(products.value?.map((product) => product.id))
    : undefined,
);

const { t } = useI18n({
  useScope: "local",
});

const showRefreshButton = ref(false);
const timer = ref<ReturnType<typeof setInterval> | null>(null);

onMounted(() => {
  watchEffect(() => {
    if (isToday(period.value.end) && !timer.value) {
      timer.value = setInterval(async () => {
        showRefreshButton.value = true;
      }, 60000);
      return;
    }

    if (timer.value) {
      clearInterval(timer.value);
      timer.value = null;
      showRefreshButton.value = false;
    }
  });
});

const { isDesktop } = useDevice();

useHead({
  title: t("title"),
});

const SalesAreaChart = defineAsyncComponent(
  () => import("~/components/Sales/SalesAreaChart.vue"),
);
const SalesTopProducts = defineAsyncComponent(
  () => import("~/components/Sales/TopProducts/SalesTopProducts.vue"),
);
const SalesTopCategories = defineAsyncComponent(
  () => import("~/components/Sales/SalesTopCategories.vue"),
);
const SalesCashiers = defineAsyncComponent(
  () => import("~/components/Sales/SalesCashiers.vue"),
);
</script>

<template>
  <div class="mx-auto max-w-7xl space-y-6 px-4 py-6 pb-20 sm:px-10">
    <div
      class="flex flex-col justify-between gap-6 sm:gap-4 md:flex-row md:items-center"
    >
      <div class="flex grow items-baseline justify-between sm:items-center">
        <h1
          class="text-balance pl-2 pt-2 text-4xl font-semibold text-primary sm:pl-0 sm:pt-0"
        >
          {{ t("title") }}
        </h1>

        <ClientOnly>
          <component
            :is="isDesktop ? UIButton : UIButtonIcon"
            v-if="showRefreshButton"
            variant="filledTonal"
            :label="t('refresh')"
            icon="ic:round-refresh"
            @click="
              async () => {
                period.end = new Date();
                await navigateTo({
                  path: $route.path,
                  query: {
                    ...$route.query,
                    periodEnd: period.end.toISOString(),
                  },
                });
                showRefreshButton = false;
              }
            "
          />
        </ClientOnly>
      </div>

      <SalesPeriodSelect
        v-model:selected-range="period"
        class="ml-auto w-full md:w-1/3 xl:w-1/5"
      />
    </div>

    <Suspense>
      <div class="rounded-xl shadow outline outline-1 outline-outline/20">
        <SalesTabs
          v-model:selected-view="view"
          :period="period"
          :category="category"
          :cashierId="cashierId"
          :productIds="productIds"
          :paymentMethodId="paymentMethodId"
        />
        <SalesAreaChart
          :view="view"
          :period="period"
          v-model:category="category"
          v-model:products="products"
          v-model:cashier="cashier"
          v-model:paymentMethod="paymentMethod"
        />
      </div>
      <template #fallback>
        <UILoading />
      </template>
    </Suspense>

    <Suspense>
      <div class="mt-4 flex flex-col gap-4 lg:flex-row">
        <SalesTopProducts
          :view="view"
          :period="period"
          :category="category"
          v-model:products="products"
          :cashier="cashier"
          :paymentMethod="paymentMethod"
          class="flex-1"
        />
        <div class="h-full flex-1 space-y-4">
          <SalesTopCategories
            :view="view"
            :period="period"
            :cashier="cashier"
            :productIds="productIds"
            :paymentMethod="paymentMethod"
            v-model:category="category"
            class="col-start-2 col-end-3 row-start-1 row-end-3 h-fit"
          />
          <SalesCashiers
            :view="view"
            :period="period"
            :category="category"
            :productIds="productIds"
            :paymentMethod="paymentMethod"
            v-model:cashier="cashier"
            class="col-start-2 col-end-3 row-start-3 row-end-3"
          />
          <SalesPaymentMethods
            :view="view"
            :period="period"
            :category="category"
            :cashier="cashier"
            :products="products"
            v-model:paymentMethod="paymentMethod"
            class="col-start-2 col-end-3 row-start-3 row-end-3"
          />
        </div>
      </div>
      <template #fallback>
        <UILoading />
      </template>
    </Suspense>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Sales & Revenue",
    "refresh": "Refresh"
  },
  "fr": {
    "title": "Ventes & Revenus",
    "refresh": "Actualiser"
  }
}
</i18n>
