import type { Socket } from "socket.io-client";

interface SocketInstance {
  connect: (storeId: string) => Socket;
  disconnect: () => void;
  isAppConnected: ComputedRef<boolean>;
  isRecentlyDisconnected: ComputedRef<boolean>;
  salesCount: Ref<number>;
  totalRevenue: Ref<number>;
}

export function useSocket(): SocketInstance {
  const currentStore = useCurrentStore();
  const nuxtApp = useNuxtApp();
  const isAppConnected = computed(
    () => nuxtApp.$socket?.isConnected?.value ?? false,
  );
  const isRecentlyDisconnected = computed(
    () => nuxtApp.$socket?.isRecentlyDisconnected?.value ?? false,
  );

  // Watch for store changes
  watch(
    () => currentStore.value?._id,
    (storeId) => {
      if (storeId) {
        nuxtApp.$socket?.connect(storeId);
      } else {
        nuxtApp.$socket?.disconnect();
      }
    },
    { immediate: true },
  );

  // Cleanup on scope dispose
  onScopeDispose(() => {
    nuxtApp.$socket?.disconnect();
  });

  return {
    connect: (storeId: string) => nuxtApp.$socket?.connect(storeId) as Socket,
    disconnect: () => nuxtApp.$socket?.disconnect(),
    isAppConnected,
    isRecentlyDisconnected,
    salesCount: computed(() => nuxtApp.$socket?.salesCount?.value ?? 0),
    totalRevenue: computed(() => nuxtApp.$socket?.totalRevenue?.value ?? 0),
  };
}
