<script setup lang="ts">
import type { switchStoreInputSchema } from "~/validations/store";
import { getAllStoresOutputSchema } from "~/validations/store";

export interface Props {
  type: "small" | "extended";
}

const props = defineProps<Props>();
const selectedStore = ref<string>();
const currentStore = useCurrentStore();
const { isAppConnected, isRecentlyDisconnected } = useSocket();

watchEffect(() => {
  selectedStore.value = currentStore.value?._id;
});

const { data: stores, execute: fetchStores } = await useFetch("/api/stores", {
  transform: (data) => getAllStoresOutputSchema.parse(data),
  immediate: false,
});

async function switchStore(storeId: string) {
  if (storeId === currentStore.value?._id) return;

  await $fetch("/api/stores/switch", {
    method: "POST",
    body: { _id: storeId } satisfies output<typeof switchStoreInputSchema>,
  });

  const pathname = useRoute().path;
  if (pathname === "/app/sales") {
    await navigateTo({
      path: pathname,
      query: undefined,
    });
  }

  reloadNuxtApp({ force: true });
}
</script>

<template>
  <UISelectRoot
    :model-value="selectedStore"
    @update:model-value="
      (value) => {
        selectedStore = value;
        switchStore(value);
      }
    "
    @update:open="
      (value) => {
        if (value) fetchStores();
      }
    "
  >
    <UISelectTrigger
      class="relative mx-auto size-10 w-fit rounded-full bg-transparent p-0 text-base"
      :class="[
        props.type === 'extended' &&
          'h-14 w-full pr-4 hover:bg-on-surface/10 focus-visible:bg-on-surface/20 active:bg-on-surface/20',
      ]"
    >
      <div v-if="!currentStore" class="flex items-center gap-2 pl-2">
        <UISkeleton class="size-10 rounded-full" />
        <UISkeleton class="hidden h-5 w-40 rounded-lg lg:block" />
      </div>

      <div
        v-if="currentStore"
        class="flex items-center"
        :class="[
          props.type === 'extended' && 'justify-between pl-2',
          props.type === 'small' && 'justify-center',
        ]"
      >
        <div class="flex items-center gap-2">
          <div class="relative">
            <NuxtImg
              :src="
                currentStore.imageUrl
                  ? `/stores/${currentStore.imageUrl}`
                  : '/images/makhbazti-logo.png'
              "
              alt=""
              class="size-10 rounded-full"
              sizes="40px"
            />
            <div
              class="absolute -right-1 -top-1 size-3 rounded-full ring-2 ring-surface"
              :class="[
                isAppConnected
                  ? 'bg-green-500'
                  : isRecentlyDisconnected
                    ? 'animate-pulse bg-orange-500'
                    : 'bg-red-400',
                'transition-colors duration-200',
              ]"
              :title="
                isAppConnected
                  ? 'App Connected'
                  : isRecentlyDisconnected
                    ? 'App Recently Disconnected'
                    : 'App Offline'
              "
            />
          </div>
          <span v-if="props.type === 'extended'" class="max-w-[200px] truncate">
            {{ currentStore.name }}
          </span>
        </div>
      </div>

      <template #icon>
        <Icon
          v-if="props.type === 'extended'"
          name="ic:outline-unfold-more"
          class="size-5 text-on-surface"
          aria-hidden="true"
        />
        <div v-else />
      </template>
    </UISelectTrigger>

    <UISelectContent
      class="rounded-xl bg-surface shadow-lg ring-1 ring-black/5 focus:outline-none"
      :class="[
        props.type === 'extended' && 'inset-x-0 mt-1 origin-top',
        props.type === 'small' && 'left-20 top-0 w-64',
      ]"
    >
      <ul v-if="!stores" class="rounded-xl bg-surface-container-low p-1">
        <li
          v-for="i in 2"
          :key="i"
          class="flex items-center gap-2 rounded-xl p-2"
        >
          <UISkeleton class="size-10 rounded-full" />
          <UISkeleton class="h-5 w-40 rounded-lg" />
        </li>
      </ul>

      <UISelectGroup v-else class="rounded-xl bg-surface-container-low p-1">
        <UISelectItem
          v-for="store in stores"
          :key="store._id"
          :value="store._id"
          indicator-position="end"
          class="group flex w-full items-center justify-between gap-2 rounded-xl text-base text-on-surface hover:bg-surface-variant focus:bg-surface-variant"
        >
          <div class="flex items-center gap-2">
            <NuxtImg
              :src="
                store.imageUrl
                  ? `/stores/${store.imageUrl}`
                  : '/images/makhbazti-logo.png'
              "
              alt="store"
              class="size-10 rounded-full"
            />
            <span class="max-w-[150px] truncate">
              {{ store.name }}
            </span>
          </div>

          <template #indicator>
            <Icon name="mdi:check" class="size-5" />
          </template>
        </UISelectItem>
      </UISelectGroup>
    </UISelectContent>
  </UISelectRoot>
</template>

<style scoped>
.connection-indicator {
  transition: background-color 0.3s ease;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
</style>
