<script setup lang="ts">
import type { View, Range } from "~/server/constants/sale";

import { differenceInDays, getISODay, subDays } from "date-fns";
import { WEEK_DAYS } from "~/data/weekDays";
import { VIEWS } from "~/server/constants/sale";
import { getSelectedPeriod } from "~/utils/sale";
import { useTransition } from "@vueuse/core";
import type { UseTransitionOptions } from "@vueuse/core";
import type { CategoryName } from "~/server/constants/product";

const props = defineProps<{
  selectedView: View;
  period: Range;
  category?: CategoryName;
  cashierId?: string;
  productIds?: string;
  paymentMethodId?: string;
}>();

const emit = defineEmits<{
  "update:selectedView": [value: View];
}>();

const storeId = computed(() => useCurrentStore().value?._id);
const periodStart = computed(() => props.period.start.toISOString());
const periodEnd = computed(() => props.period.end.toISOString());
const category = computed(() => props.category);
const cashierId = computed(() => props.cashierId);
const productIds = computed(() => props.productIds);
const paymentMethodId = computed(() => props.paymentMethodId);
const query = {
  storeId,
  periodStart,
  periodEnd,
  category,
  cashierId,
  productIds,
  paymentMethodId,
};

const { data: totalSaleCount, status: totalSaleCountStatus } =
  await useLazyFetch("/api/sales/sale-count", {
    query,
    server: false,
  });
const { data: totalSalesRevenue, status: totalSalesRevenueStatus } =
  await useLazyFetch("/api/sales/sales-revenue", {
    query,
    server: false,
  });
const { data: averageRevenuePerSale, status: averageRevenuePerSaleStatus } =
  await useLazyFetch("/api/sales/average-revenue-per-sale", {
    query,
    server: false,
  });

const dataPending = computed(() => {
  switch (props.selectedView) {
    case "sales":
      return (
        totalSaleCountStatus.value === "idle" ||
        totalSaleCountStatus.value === "pending"
      );
    case "revenue":
      return (
        totalSalesRevenueStatus.value === "idle" ||
        totalSalesRevenueStatus.value === "pending"
      );
    case "average-revenue":
      return (
        averageRevenuePerSaleStatus.value === "idle" ||
        averageRevenuePerSaleStatus.value === "pending"
      );
    default:
      return false;
  }
});

const { t, locale } = useI18n({
  useScope: "local",
});

const periodLength = computed(() => {
  const selectedPeriod = getSelectedPeriod(props.period);

  const oneDayMessage = (date: Date) =>
    `${t("the")} ${locale.value === "en" ? "previous" : ""} ${t(WEEK_DAYS[getISODay(date) - 1])} ${locale.value === "fr" ? "précédent" : ""}`;

  switch (selectedPeriod) {
    case "today":
      return oneDayMessage(new Date());
    case "this-week":
      return t("previous-week");
    case "this-month":
      return t("previous-month");
  }

  const daysCount = differenceInDays(props.period.end, props.period.start) + 1;

  if (daysCount === 1) {
    return oneDayMessage(props.period.start);
  }

  if (daysCount < 7) {
    return `${t("previous-week")} (${t(WEEK_DAYS[getISODay(subDays(props.period.start, 7)) - 1])} - ${t(WEEK_DAYS[getISODay(subDays(props.period.end, 7)) - 1])})`;
  }

  return `${t("the", daysCount)} ${locale.value === "en" ? "previous" : ""} ${daysCount > 1 ? daysCount : ""} ${t("day", daysCount)} ${locale.value === "fr" ? "précédent" + (daysCount > 1 ? "s" : "") : ""}`;
});

// Mechanical counter animation settings
const duration = 2000;
const baseTransitionOptions: UseTransitionOptions = {
  duration,
  transition: (p: number) => {
    // Easing function that gives a mechanical feel
    return 1 - Math.pow(1 - p, 4); // Power easing out
  },
  disabled: false,
};

// Configure transitions with enhanced settings
const totalSaleCountBaseNumber = ref(0);
const totalSaleCountNumber = useTransition(
  totalSaleCountBaseNumber,
  baseTransitionOptions,
);

const totalSalesRevenueBaseNumber = ref(0);
const totalSalesRevenueNumber = useTransition(
  totalSalesRevenueBaseNumber,
  baseTransitionOptions,
);

const averageRevenuePerSaleBaseNumber = ref(0);
const averageRevenuePerSaleNumber = useTransition(
  averageRevenuePerSaleBaseNumber,
  baseTransitionOptions,
);

const getData = (view: View) => {
  switch (view) {
    case "sales":
      return totalSaleCountNumber;
    case "revenue":
      return totalSalesRevenueNumber;
    case "average-revenue":
      return averageRevenuePerSaleNumber;
  }
};

// Watch for API data changes
watchEffect(() => {
  if (totalSaleCount.value?.value !== undefined) {
    totalSaleCountBaseNumber.value = totalSaleCount.value.value;
  }
});

watchEffect(() => {
  if (totalSalesRevenue.value?.value !== undefined) {
    totalSalesRevenueBaseNumber.value = totalSalesRevenue.value.value;
  }
});

watchEffect(() => {
  if (averageRevenuePerSale.value?.value !== undefined) {
    averageRevenuePerSaleBaseNumber.value = averageRevenuePerSale.value.value;
  }
});

// Watch for real-time updates with proper typing
const { salesCount, totalRevenue } = useSocket();

const hasActiveFilters = computed(() => {
  return !!(
    props.category ||
    props.cashierId ||
    props.productIds ||
    props.paymentMethodId ||
    // Check if period is not today
    differenceInDays(props.period.end, props.period.start) > 0 ||
    props.period.start.getDate() !== new Date().getDate()
  );
});

watch(
  [salesCount, totalRevenue],
  (newValues) => {
    // Only update if no filters are active
    if (hasActiveFilters.value) return;

    const [newSalesCount, newTotalRevenue] = newValues;
    const currentCount = totalSaleCountBaseNumber.value;
    const currentRevenue = totalSalesRevenueBaseNumber.value;

    const salesCountValue = Number(newSalesCount);
    const revenueValue = Number(newTotalRevenue);

    if (salesCountValue > currentCount) {
      totalSaleCountBaseNumber.value = salesCountValue;
    }
    if (revenueValue > currentRevenue) {
      totalSalesRevenueBaseNumber.value = revenueValue;
      if (salesCountValue > 0) {
        averageRevenuePerSaleBaseNumber.value = revenueValue / salesCountValue;
      }
    }
  },
  { immediate: true },
);

// Add class for number animation with pulse effect
const getNumberClass = (currentView: View) => [
  "posthog-block text-3xl font-bold transition-all duration-300",
  "transform hover:scale-105",
  props.selectedView === currentView ? "text-on-surface" : "text-on-surface/70",
  "number-animate",
];

// Initialize socket connection
const { $socket } = useNuxtApp();
const currentStore = useCurrentStore();

// Setup socket connection and initial data sync
onMounted(() => {
  if (currentStore.value?._id) {
    $socket?.connect(currentStore.value._id);
    // Sync initial values with socket
    if (totalSaleCount.value?.value !== undefined && $socket?.updateSalesData) {
      $socket.updateSalesData(
        totalSaleCount.value.value,
        totalSalesRevenue.value?.value ?? 0,
      );
    }
  }
});

// Watch for API data changes to sync with socket
watchEffect(() => {
  if (totalSaleCount.value?.value !== undefined && $socket?.updateSalesData) {
    $socket.updateSalesData(
      totalSaleCount.value.value,
      totalSalesRevenue.value?.value ?? 0,
    );
  }
});

// Watch for real-time updates
const salesCountRef = computed(() => $socket?.salesCount?.value ?? 0);
const totalRevenueRef = computed(() => $socket?.totalRevenue?.value ?? 0);

watch(
  [salesCountRef, totalRevenueRef],
  ([newCount, newRevenue]) => {
    // Only update if no filters are active
    if (hasActiveFilters.value) return;

    if (newCount > 0) {
      totalSaleCountBaseNumber.value = newCount;
    }
    if (newRevenue > 0) {
      totalSalesRevenueBaseNumber.value = newRevenue;
      if (newCount > 0) {
        averageRevenuePerSaleBaseNumber.value = newRevenue / newCount;
      }
    }
  },
  { immediate: true },
);

// Cleanup
onUnmounted(() => {
  $socket?.disconnect();
});

// Watch for store changes
watch(
  () => currentStore.value?._id,
  (storeId) => {
    if (storeId) {
      $socket?.connect(storeId);
    } else {
      $socket?.disconnect();
    }
  },
);

// Track previous values for comparison
const previousValues = ref({
  sales: "0",
  revenue: "0",
  "average-revenue": "0",
});

// Watch for value changes and update previous values
watch(
  () => getData("sales").value,
  (newVal) => {
    previousValues.value.sales = numberFormat(Number(newVal.toFixed(0)));
  },
);

watch(
  () => getData("revenue").value,
  (newVal) => {
    previousValues.value.revenue = numberFormat(Number(newVal.toFixed(0)));
  },
);

watch(
  () => getData("average-revenue").value,
  (newVal) => {
    previousValues.value["average-revenue"] = newVal.toFixed(2);
  },
);
</script>

<template>
  <UIScrollArea
    class="max-w-[calc(100vw-32px)] whitespace-nowrap rounded-t-xl bg-surface sm:max-w-[calc(100vw-80px)] md:max-w-full"
  >
    <ul class="flex w-fit border-b border-outline/40">
      <li v-for="view in VIEWS" :key="view" class="w-full">
        <button
          @click="
            () => {
              emit('update:selectedView', view);
              return navigateTo({
                query: {
                  ...$route.query,
                  view,
                },
              });
            }
          "
          class="flex min-w-48 flex-1 flex-col gap-3 border-r border-outline/40 px-6 py-4 focus-visible:outline-black md:min-w-60"
          :class="[
            view === selectedView
              ? 'border-b-2 border-b-on-surface bg-surface-container-lowest'
              : 'bg-surface-container-lowest/40',
          ]"
        >
          <span class="font-medium text-on-surface/80 first-letter:capitalize">
            {{ t(view, { unit: useCurrency().value }) }}
          </span>

          <div class="flex items-center gap-4">
            <UISkeleton v-if="dataPending" class="h-9 w-24" />
            <div v-else :class="getNumberClass(view)">
              <template v-if="view === 'average-revenue'">
                {{ getData(view).value.toFixed(2) }}
              </template>
              <template v-else>
                {{ numberFormat(Number(getData(view).value.toFixed(0))) }}
              </template>
            </div>

            <UITooltipProvider v-if="view === 'sales' && totalSaleCount?.trend">
              <UITooltip>
                <UITooltipTrigger as-child>
                  <span
                    class="rounded-md px-2 py-1 text-sm font-medium"
                    :class="
                      totalSaleCount?.trend > 0
                        ? 'bg-green-800/10 text-green-800'
                        : 'bg-red-800/10 text-red-700'
                    "
                  >
                    {{ totalSaleCount?.trend > 0 ? "+" : "-"
                    }}{{ Math.abs(Math.round(totalSaleCount?.trend)) }}%
                  </span>
                </UITooltipTrigger>
                <UITooltipContent
                  side="bottom"
                  class="w-fit max-w-[28ch] text-balance p-4"
                >
                  <p class="text-sm">
                    {{
                      t("trendDescription", {
                        content: `${Math.abs(Math.round(totalSaleCount?.trend))}% ${totalSaleCount?.trend > 0 ? t("more") : t("less")}`,
                        subject: t("sales"),
                        periodLength,
                      })
                    }}
                  </p>
                </UITooltipContent>
              </UITooltip>
            </UITooltipProvider>

            <UITooltipProvider
              v-if="view === 'revenue' && totalSalesRevenue?.trend"
            >
              <UITooltip>
                <UITooltipTrigger as-child>
                  <span
                    class="rounded-md px-2 py-1 text-sm font-medium"
                    :class="
                      totalSalesRevenue?.trend > 0
                        ? 'bg-green-800/10 text-green-800'
                        : 'bg-red-800/10 text-red-700'
                    "
                  >
                    {{ totalSalesRevenue?.trend > 0 ? "+" : "-"
                    }}{{ Math.abs(totalSalesRevenue?.trend) }}%
                  </span>
                </UITooltipTrigger>
                <UITooltipContent
                  side="bottom"
                  class="w-fit max-w-[28ch] text-balance p-4"
                >
                  <p class="text-sm">
                    {{
                      t("trendDescription", {
                        content: `${Math.abs(Math.round(totalSalesRevenue?.trend))}% ${totalSalesRevenue?.trend > 0 ? t("more") : t("less")}`,
                        subject: t("revenue"),
                        periodLength,
                      })
                    }}
                  </p>
                </UITooltipContent>
              </UITooltip>
            </UITooltipProvider>

            <UITooltipProvider
              v-if="view === 'average-revenue' && averageRevenuePerSale?.trend"
            >
              <UITooltip>
                <UITooltipTrigger as-child>
                  <span
                    class="rounded-md px-2 py-1 text-sm font-medium"
                    :class="
                      averageRevenuePerSale?.trend > 0
                        ? 'bg-green-800/10 text-green-800'
                        : 'bg-red-800/10 text-red-700'
                    "
                  >
                    {{ averageRevenuePerSale?.trend > 0 ? "+" : "-"
                    }}{{ Math.abs(averageRevenuePerSale?.trend) }}%
                  </span>
                </UITooltipTrigger>
                <UITooltipContent
                  side="bottom"
                  class="w-fit max-w-[28ch] text-balance p-4"
                >
                  <p class="text-sm">
                    {{
                      t("trendDescription", {
                        content: `${Math.abs(averageRevenuePerSale?.trend)}% ${averageRevenuePerSale?.trend > 0 ? t("more") : t("less")}`,
                        subject: t("average-revenue"),
                        periodLength,
                      })
                    }}
                  </p>
                </UITooltipContent>
              </UITooltip>
            </UITooltipProvider>
          </div>
        </button>
      </li>
    </ul>

    <UIScrollAreaBar orientation="horizontal" />
  </UIScrollArea>
</template>

<style scoped>
.posthog-block {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 700;
  animation: numberChange 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

@keyframes numberChange {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.text-on-surface {
  color: var(--color-on-surface);
}

.text-on-surface\/70 {
  color: var(--color-on-surface / 0.7);
}
</style>

<i18n lang="json">
{
  "en": {
    "sales": "sales",
    "revenue": "revenue",
    "average-revenue": "average revenue",
    "trendDescription": "{content} {subject} than the {periodLength}",
    "more": "more",
    "less": "less",
    "yesterday": "yesterday",
    "previous-week": "previous week",
    "previous-month": "previous month",
    "the": "the",
    "day": "day | days",
    "monday": "Monday",
    "tuesday": "Tuesday",
    "wednesday": "Wednesday",
    "thursday": "Thursday",
    "friday": "Friday",
    "saturday": "Saturday",
    "sunday": "Sunday"
  },
  "fr": {
    "sales": "ventes",
    "revenue": "chiffre d'affaire",
    "average-revenue": "revenu moyen",
    "trendDescription": "{content} de {subject} que {periodLength}",
    "more": "plus",
    "less": "moins",
    "yesterday": "hier",
    "previous-week": "la semaine précédente",
    "previous-month": "le mois précédent",
    "the": "le | les",
    "day": "jour | jours",
    "monday": "lundi",
    "tuesday": "mardi",
    "wednesday": "mercredi",
    "thursday": "jeudi",
    "friday": "vendredi",
    "saturday": "samedi",
    "sunday": "dimanche"
  }
}
</i18n>
