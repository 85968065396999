import revive_payload_client_ApfH5Eu0ix from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jt7Jd2Huvm from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_x3zAlJESXO from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kFETR5wwig from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_aM6aajL5gG from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_181bKWhyeU from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import view_transitions_client_GfjHiqxiWL from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/app/plugins/view-transitions.client.js";
import chunk_reload_client_lXbDqzLaBi from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_J8w5HqQ3KU from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_oQZmRbVh6Z from "/app/node_modules/.pnpm/nuxt-bugsnag@8.3.1_@bugsnag+core@8.1.1_magicast@0.3.5_rollup@4.32.0/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_client_tN72JH81xj from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.2_magicast@0.3.5_rollup@4.32.0/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_wy0B721ODc from "/app/node_modules/.pnpm/@nuxtjs+device@3.2.4/node_modules/@nuxtjs/device/dist/runtime/plugin.js";
import switch_locale_path_ssr_aqalUo7E9e from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jvi5m2waoxhxsvu7a33oiwy6fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_hzmI6S6kgm from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jvi5m2waoxhxsvu7a33oiwy6fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_XO08GLW3gz from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jvi5m2waoxhxsvu7a33oiwy6fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_k29zxtyEav from "/app/node_modules/.pnpm/@nuxt+icon@1.10.3_magicast@0.3.5_rollup@4.32.0_vite@6.0.11_@types+node@22.10.10_jiti@2.4.2_te_l2cj2dwsj5nl24pd2nyhg23hta/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import posthog_client_fUucxKWhZE from "/app/plugins/posthog.client.ts";
import socket_client_qBDI6bzE0L from "/app/plugins/socket.client.ts";
import ssg_detect_Pjp0YtT5vA from "/app/node_modules/.pnpm/@nuxtjs+i18n@9.1.3_@vue+compiler-dom@3.5.13_eslint@9.19.0_jiti@2.4.2__magicast@0.3.5_rollup@4_jvi5m2waoxhxsvu7a33oiwy6fe/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_ApfH5Eu0ix,
  unhead_jt7Jd2Huvm,
  router_x3zAlJESXO,
  payload_client_kFETR5wwig,
  navigation_repaint_client_aM6aajL5gG,
  check_outdated_build_client_181bKWhyeU,
  view_transitions_client_GfjHiqxiWL,
  chunk_reload_client_lXbDqzLaBi,
  components_plugin_KR1HBZs4kY,
  prefetch_client_J8w5HqQ3KU,
  plugin_oQZmRbVh6Z,
  plugin_client_tN72JH81xj,
  plugin_wy0B721ODc,
  switch_locale_path_ssr_aqalUo7E9e,
  route_locale_detect_hzmI6S6kgm,
  i18n_XO08GLW3gz,
  plugin_k29zxtyEav,
  posthog_client_fUucxKWhZE,
  socket_client_qBDI6bzE0L,
  ssg_detect_Pjp0YtT5vA
]