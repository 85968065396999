
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46404_935HoX6yqDILMeta } from "/app/pages/[...404].vue?macro=true";
import { default as _91id_93hsAWegN2iBMeta } from "/app/pages/app/employees/[id].vue?macro=true";
import { default as createf3uztPtumdMeta } from "/app/pages/app/employees/create.vue?macro=true";
import { default as indexE2eWPUjcx1Meta } from "/app/pages/app/employees/index.vue?macro=true";
import { default as _91id_93QOH0B8yoN4Meta } from "/app/pages/app/employees/update/[id].vue?macro=true";
import { default as _91id_93MPS3Yv1FrTMeta } from "/app/pages/app/expenses/[id].vue?macro=true";
import { default as createjxeRuVN0R6Meta } from "/app/pages/app/expenses/create.vue?macro=true";
import { default as indexn0pAYxe8uzMeta } from "/app/pages/app/expenses/index.vue?macro=true";
import { default as _91id_93Kb2uYmxwMFMeta } from "/app/pages/app/products/[id].vue?macro=true";
import { default as createKmz0uKBSjTMeta } from "/app/pages/app/products/create.vue?macro=true";
import { default as indexmZf4oXmMlsMeta } from "/app/pages/app/products/index.vue?macro=true";
import { default as _91id_93usCmdIQMMvMeta } from "/app/pages/app/products/update/[id].vue?macro=true";
import { default as salesLa4WdhtQvNMeta } from "/app/pages/app/sales.vue?macro=true";
import { default as settingsNsH9O5gPg4Meta } from "/app/pages/app/settings.vue?macro=true";
import { default as indexetuDDeui6gMeta } from "/app/pages/app/stock/index.vue?macro=true";
import { default as createV2udcmdBz8Meta } from "/app/pages/app/suppliers/create.vue?macro=true";
import { default as indexUifsXTB7LFMeta } from "/app/pages/app/suppliers/index.vue?macro=true";
import { default as _91id_93g0JtT6Wh2OMeta } from "/app/pages/app/suppliers/update/[id].vue?macro=true";
import { default as appuxecjmUeNfMeta } from "/app/pages/app.vue?macro=true";
import { default as join_45usHD2ytryoHsMeta } from "/app/pages/auth/join-us.vue?macro=true";
import { default as _91token_93_45_91phone_93nxC99TIFf2Meta } from "/app/pages/auth/login/[token]-[phone].vue?macro=true";
import { default as indexlXWKW23Hj9Meta } from "/app/pages/auth/login/index.vue?macro=true";
import { default as _91token_93_45_91phone_93g3aXCK9rkqMeta } from "/app/pages/auth/passkey-reset/[token]-[phone].vue?macro=true";
import { default as auth7tE1sZcZDUMeta } from "/app/pages/auth.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as privacy_45policyz5PUdDuYlNMeta } from "/app/pages/legal/privacy-policy.vue?macro=true";
import { default as terms_45of_45useHZnXalT21JMeta } from "/app/pages/legal/terms-of-use.vue?macro=true";
import { default as component_45stubQodjFKZutjMeta } from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubQodjFKZutj } from "/app/node_modules/.pnpm/nuxt@3.15.3_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_eslint@9.19.0_jiti@2.4.2__id_w6wp2jdefycwgauljspcvwv7q4/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "404",
    path: "/:404(.*)*",
    component: () => import("/app/pages/[...404].vue")
  },
  {
    name: "app",
    path: "/app",
    meta: appuxecjmUeNfMeta || {},
    component: () => import("/app/pages/app.vue"),
    children: [
  {
    name: "app-employees-id",
    path: "employees/:id()",
    component: () => import("/app/pages/app/employees/[id].vue")
  },
  {
    name: "app-employees-create",
    path: "employees/create",
    component: () => import("/app/pages/app/employees/create.vue")
  },
  {
    name: "app-employees",
    path: "employees",
    component: () => import("/app/pages/app/employees/index.vue")
  },
  {
    name: "app-employees-update-id",
    path: "employees/update/:id()",
    component: () => import("/app/pages/app/employees/update/[id].vue")
  },
  {
    name: "app-expenses-id",
    path: "expenses/:id()",
    meta: _91id_93MPS3Yv1FrTMeta || {},
    component: () => import("/app/pages/app/expenses/[id].vue")
  },
  {
    name: "app-expenses-create",
    path: "expenses/create",
    component: () => import("/app/pages/app/expenses/create.vue")
  },
  {
    name: "app-expenses",
    path: "expenses",
    component: () => import("/app/pages/app/expenses/index.vue")
  },
  {
    name: "app-products-id",
    path: "products/:id()",
    meta: _91id_93Kb2uYmxwMFMeta || {},
    component: () => import("/app/pages/app/products/[id].vue")
  },
  {
    name: "app-products-create",
    path: "products/create",
    component: () => import("/app/pages/app/products/create.vue")
  },
  {
    name: "app-products",
    path: "products",
    component: () => import("/app/pages/app/products/index.vue")
  },
  {
    name: "app-products-update-id",
    path: "products/update/:id()",
    component: () => import("/app/pages/app/products/update/[id].vue")
  },
  {
    name: "app-sales",
    path: "sales",
    component: () => import("/app/pages/app/sales.vue")
  },
  {
    name: "app-settings",
    path: "settings",
    component: () => import("/app/pages/app/settings.vue")
  },
  {
    name: "app-stock",
    path: "stock",
    component: () => import("/app/pages/app/stock/index.vue")
  },
  {
    name: "app-suppliers-create",
    path: "suppliers/create",
    component: () => import("/app/pages/app/suppliers/create.vue")
  },
  {
    name: "app-suppliers",
    path: "suppliers",
    component: () => import("/app/pages/app/suppliers/index.vue")
  },
  {
    name: "app-suppliers-update-id",
    path: "suppliers/update/:id()",
    component: () => import("/app/pages/app/suppliers/update/[id].vue")
  }
]
  },
  {
    name: "auth",
    path: "/auth",
    meta: auth7tE1sZcZDUMeta || {},
    component: () => import("/app/pages/auth.vue"),
    children: [
  {
    name: "auth-join-us",
    path: "join-us",
    component: () => import("/app/pages/auth/join-us.vue")
  },
  {
    name: "auth-login-token-phone",
    path: "login/:token()-:phone()",
    meta: _91token_93_45_91phone_93nxC99TIFf2Meta || {},
    component: () => import("/app/pages/auth/login/[token]-[phone].vue")
  },
  {
    name: "auth-login",
    path: "login",
    component: () => import("/app/pages/auth/login/index.vue")
  },
  {
    name: "auth-passkey-reset-token-phone",
    path: "passkey-reset/:token()-:phone()",
    component: () => import("/app/pages/auth/passkey-reset/[token]-[phone].vue")
  }
]
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "legal-privacy-policy",
    path: "/legal/privacy-policy",
    component: () => import("/app/pages/legal/privacy-policy.vue")
  },
  {
    name: "legal-terms-of-use",
    path: "/legal/terms-of-use",
    component: () => import("/app/pages/legal/terms-of-use.vue")
  },
  {
    name: component_45stubQodjFKZutjMeta?.name,
    path: "/en",
    component: component_45stubQodjFKZutj
  },
  {
    name: component_45stubQodjFKZutjMeta?.name,
    path: "/dashboard",
    component: component_45stubQodjFKZutj
  },
  {
    name: component_45stubQodjFKZutjMeta?.name,
    path: "/sales",
    component: component_45stubQodjFKZutj
  },
  {
    name: component_45stubQodjFKZutjMeta?.name,
    path: "/products",
    component: component_45stubQodjFKZutj
  },
  {
    name: component_45stubQodjFKZutjMeta?.name,
    path: "/login",
    component: component_45stubQodjFKZutj
  },
  {
    name: component_45stubQodjFKZutjMeta?.name,
    path: "/join-us",
    component: component_45stubQodjFKZutj
  }
]