import { CATEGORIES, PRODUCT_UNITS } from "~/server/constants/product";
import { VIEWS } from "~/server/constants/sale";
import { idSchema, imageUrlSchema, nameSchema } from "./shared";
import { z } from "zod";

export const periodDateSchema = z.coerce.date();

export const saleInputSchema = z.object({
  periodStart: periodDateSchema.optional(),
  periodEnd: periodDateSchema.optional(),
  category: z.enum(CATEGORIES).optional(),
  limit: z.number().transform((val) => String(val)),
  storeId: idSchema,
  cashierId: idSchema.optional(),
  productId: idSchema.optional(),
  paymentMethodId: idSchema.optional(),
});

export const searchParamsSchema = z.object({
  view: z.enum(VIEWS).optional(),
  periodStart: periodDateSchema.optional(),
  periodEnd: periodDateSchema.optional(),
  category: z.enum(CATEGORIES).optional(),
  cashierId: z.string().optional(),
  cashierName: z.string().optional(),
  products: z.string().optional(),
  paymentMethodId: z.string().optional(),
  paymentMethodName: z.string().optional(),
});

export const salesChartOutputSchema = z.array(
  z.object({
    x: z.string(),
    y: z.number().nonnegative(),
  }),
);

export const valueSchema = z.number().nonnegative();
export const trendSchema = z.number();

export const saleCountOutputSchema = z.object({
  value: valueSchema,
  trend: trendSchema,
});

export const salesRevenueOutputSchema = z.object({
  value: valueSchema,
  trend: trendSchema,
});

export const averageRevenuePerSaleOutputSchema = z.object({
  value: valueSchema,
  trend: trendSchema,
});

export const quantitySoldOutputSchema = z.object({
  value: valueSchema,
  unit: z.enum(PRODUCT_UNITS),
  trend: trendSchema,
});

export const productOutputSchema = z.object({
  _id: idSchema,
  name: nameSchema,
  imageUrl: imageUrlSchema,
});

export const categoryOutputSchema = z.object({
  name: z.enum(CATEGORIES),
});

export const cashierOutputSchema = z.object({
  _id: idSchema,
  name: nameSchema,
});

export const quantitySoldByProductOutputSchema = quantitySoldOutputSchema
  .merge(productOutputSchema)
  .array();

export const salesRevenueByProductOutputSchema = salesRevenueOutputSchema
  .merge(productOutputSchema)
  .array();

export const averageRevenueByProductOutputSchema =
  averageRevenuePerSaleOutputSchema.merge(productOutputSchema).array();

export const saleCountByCategoryOutputSchema = saleCountOutputSchema
  .merge(categoryOutputSchema)
  .array();

export const salesRevenueByCategoryOutputSchema = salesRevenueOutputSchema
  .merge(categoryOutputSchema)
  .array();

export const averageRevenueByCategoryOutputSchema =
  averageRevenuePerSaleOutputSchema.merge(categoryOutputSchema).array();

export const saleCountByCashierOutputSchema = saleCountOutputSchema
  .merge(cashierOutputSchema)
  .array();

export const salesRevenueByCashierOutputSchema = salesRevenueOutputSchema
  .merge(cashierOutputSchema)
  .array();

export const averageRevenueByCashierOutputSchema =
  averageRevenuePerSaleOutputSchema.merge(cashierOutputSchema).array();

export const paymentMethodOutputSchema = z.object({
  _id: idSchema,
  name: nameSchema,
});

export const saleCountByPaymentMethodOutputSchema = saleCountOutputSchema
  .merge(paymentMethodOutputSchema)
  .array();

export const salesRevenueByPaymentMethodOutputSchema = salesRevenueOutputSchema
  .merge(paymentMethodOutputSchema)
  .array();

export const averageRevenueByPaymentMethodOutputSchema =
  averageRevenuePerSaleOutputSchema.merge(paymentMethodOutputSchema).array();

// Define the schema for metric values
const metricSchema = z.object({
  value: z.number(),
  trend: z.number().optional(),
});

// Define the schema for the complete metrics response
export const salesMetricsSchema = z.object({
  totalSaleCount: metricSchema,
  totalSalesRevenue: metricSchema,
  averageRevenuePerSale: metricSchema,
});

// Export the inferred type
export type SalesMetricsOutput = z.infer<typeof salesMetricsSchema>;
