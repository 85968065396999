import { io, type Socket } from "socket.io-client";

// Define the socket instance type
interface SocketInstance {
  connect: (storeId: string) => Socket;
  disconnect: () => void;
  isConnected: ComputedRef<boolean>;
  isRecentlyDisconnected: ComputedRef<boolean>;
  salesCount: Readonly<Ref<number>>;
  totalRevenue: Readonly<Ref<number>>;
  updateSalesData: (newCount: number, newRevenue: number) => void;
}

export default defineNuxtPlugin(() => {
  let socket: Socket | null = null;
  const isAppConnected = ref(false);
  const lastConnectedAt = ref<Date | null>(null);
  let disconnectionTimer: NodeJS.Timeout | null = null;

  // Sales event state
  const salesCount = ref(0);
  const totalRevenue = ref(0);

  // Function to update sales data
  function updateSalesData(newCount: number, newRevenue: number) {
    if (newCount > salesCount.value) {
      salesCount.value = newCount;
    }
    if (newRevenue > totalRevenue.value) {
      totalRevenue.value = newRevenue;
    }
  }

  function setupSocketListeners(socket: Socket) {
    socket.on("app:connection", (status: { connected: boolean }) => {
      isAppConnected.value = status.connected;
      if (status.connected) {
        lastConnectedAt.value = new Date();
        if (disconnectionTimer) {
          clearTimeout(disconnectionTimer);
          disconnectionTimer = null;
        }
      } else {
        lastConnectedAt.value = new Date();
        if (disconnectionTimer) clearTimeout(disconnectionTimer);
        disconnectionTimer = setTimeout(() => {
          lastConnectedAt.value = null;
        }, 60 * 1000);
      }
    });

    socket.on("sale:new", (data: { timestamp: string; totalPrice: number }) => {
      console.log("New sale:", data);
      // Update values using the updateSalesData function
      updateSalesData(
        salesCount.value + 1,
        totalRevenue.value + data.totalPrice,
      );
    });
  }

  // const config = useRuntimeConfig();

  const socketInstance: SocketInstance = {
    connect: (storeId: string) => {
      if (socket) {
        socket.disconnect();
        socket = null;
      }

      socket = io("wss://api.makhbazti.tn", {
        path: "/socket",
        auth: {
          storeId,
          clientType: "web",
        },
        transports: ["websocket"],
        secure: true,
        rejectUnauthorized: false,
        forceNew: true,
      });

      setupSocketListeners(socket);
      return socket;
    },
    disconnect: () => {
      if (socket) {
        socket.disconnect();
        socket = null;
      }
    },
    isConnected: computed(() => isAppConnected.value),
    isRecentlyDisconnected: computed(() => {
      if (isAppConnected.value) return false;
      if (!lastConnectedAt.value) return false;
      const oneMinuteAgo = new Date(Date.now() - 60 * 1000);
      return lastConnectedAt.value > oneMinuteAgo;
    }),
    salesCount: readonly(salesCount),
    totalRevenue: readonly(totalRevenue),
    updateSalesData,
  };

  return {
    provide: {
      socket: socketInstance,
    },
  };
});
