<script setup lang="ts">
import { version } from "~/package.json";

const isDrawerOpen = useIsDrawerOpen();
const { isAppConnected, isRecentlyDisconnected } = useSocket();

const toggleDrawer = () => {
  isDrawerOpen.value = !isDrawerOpen.value;
};
</script>

<template>
  <div class="bg-surface">
    <div class="flex items-center gap-3 bg-surface-container-low px-1 py-2">
      <div class="relative inline-flex">
        <UIButtonIcon
          icon="ic:outline-menu"
          label="Menu"
          class="text-on-surface-variant"
          @click="toggleDrawer"
        />
        <!-- Connection status badge -->
        <div
          class="absolute right-2 top-2.5 size-3 rounded-full ring-1 ring-surface transition-colors duration-200"
          :class="[
            isAppConnected
              ? 'bg-green-500'
              : isRecentlyDisconnected
                ? 'animate-pulse bg-yellow-500'
                : 'bg-red-500',
          ]"
        />
      </div>

      <div class="flex items-end gap-2">
        <ClientOnly>
          <NuxtImg
            :src="
              $colorMode.value === 'light'
                ? '/images/makhbazti-logo-name.webp'
                : '/images/logohoriz.png'
            "
            alt="Makhbazti logo"
            class="h-7 w-[138px] object-contain object-left"
            width="707"
            height="145"
            sizes="138px"
          />

          <template #fallback>
            <div class="h-7 w-[138px]" />
          </template>
        </ClientOnly>

        <p class="text-xs text-on-surface-variant">v{{ version }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}
</style>
